import { Injectable } from '@angular/core';
import { ExperienceRepository } from "../domain/experience.repository";
import { Experience } from "../domain/experience";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiGetExperiencesResponse } from "./api-get-experiences-response";
import { lastValueFrom } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { Experiences } from "src/core/experiences/domain/experiences";

@Injectable({
  providedIn: 'any',
})
export class HttpExperienceRepository extends ExperienceRepository {
  constructor(private readonly http: HttpClient, private readonly sessionService: SessionService) {
    super();
  }

  async load(): Promise<Experiences> {
    const language = this.sessionService.currentLanguage();
    const apiResponse = await this.loadResponseMocked(language);
    return new Experiences(this.parseApiLoadResponse(apiResponse));
  }

  private async loadResponseMocked(language: string): Promise<ApiGetExperiencesResponse> {
    return await lastValueFrom(this.http.get('/assets/api-mocked/get-experiences.response.json')) as ApiGetExperiencesResponse;
  }

  private async loadResponse(language: string): Promise<ApiGetExperiencesResponse> {
    const url = 'https://gaudi-pre-ca-mobile-api.agreeableground-6627b218.northeurope.azurecontainerapps.io/api/Experiences';
    const apikey = 'cb001aea-af6e-4ff1-a335-45bcb797f824';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', '1.0');
    let httpParams = new HttpParams();
    httpParams = httpParams.set('fakeData', 'true');
    const options = { params: httpParams, headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetExperiencesResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetExperiencesResponse): Experience[] {
    return response.experiences.map(experienceData => {
      let name = experienceData.name;
      return new Experience(experienceData.id, name);
    });
  }
}
