import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet, ModalController, ModalOptions, Platform } from '@ionic/angular/standalone';
import { WizardPage } from "./components/wizard-modal/wizard.page";
import { SessionService } from "src/core/session/domain/session.service";
import { StorageService } from "src/core/shared/storage/domain/storage.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [ IonApp, IonRouterOutlet ],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private sessionService: SessionService,
    private modalController: ModalController,
  ) {
    this.initializeApp().then().catch();
  }

  async initializeApp() {
    await this.platform.ready();
    await this.storageService.ready();
    this.printCurrentPlatformInfo();
    await this.sessionService.init();

    if (!this.sessionService.wizardIsDone()) {
      await this.openWizard();
    }
  }

  private async openWizard() {
    const options: ModalOptions = {
      component: WizardPage,
    };
    const modal = await this.modalController.create(options);
    await modal.present();
  }

  private printCurrentPlatformInfo(): void {
    // eslint-disable-next-line no-console
    console.info(`%c Platform: ${this.platform.platforms().join(', ')}`, 'background: #222; color: #bada55');
  }
}
