export abstract class TranslatorService {
  public static readonly DEFAULT_LANGUAGE: string = 'en';
  public static readonly AVAILABLE_LANGUAGES: string[] = [ 'es', 'en', 'ca' ];

  abstract instant(key: string, params?: object): string;

  abstract translate(key: string, params?: object): Promise<string>;

  abstract changeLanguage(language: string): void;

  abstract language(): string;

  abstract defaultLanguage(): string;
}
