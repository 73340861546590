<ion-header [translucent]="true" class="ion-no-border">
  <ion-toolbar>
    @if (!showingLanguageChooserPage && !lastSlide) {
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          {{ 'WIZARD.SKIP' | translate }}
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="centered-axis-xy" [class.hidden]="!showingLanguageChooserPage">
    <h1 class="title">{{ 'WIZARD.FIRST.TITLE' | translate }}</h1>

    <div class="text">{{ 'WIZARD.FIRST.TEXT' | translate }}</div>

    <div class="language-selector">
      <h2 class="subtitle">{{ 'WIZARD.FIRST.CHOOSE_LANGUAGE' | translate }}</h2>

      <ion-select
        fill="outline"
        okText="{{ 'COMMON.OK' | translate }}"
        cancelText="{{ 'COMMON.CANCEL' | translate }}"
        [(ngModel)]="selectedLanguage"
        (ionChange)="onLanguageChange($event)"
      >
        @for (language of availableLanguages; track language) {
          <ion-select-option
            value="{{ language.id }}">
            {{ language.label }}
          </ion-select-option>
        }
      </ion-select>
    </div>
  </div>

  <swiper-container
    #swiper
    pagination="true"
    [class.hidden]="showingLanguageChooserPage"
    (swiperslidechange)="swiperSlideChanged()"
  >
    @for (slide of slides; track slide) {
      <swiper-slide>
        <div class="centered-axis-xy">
          <div class="image"></div>
          <h1 class="title">{{ slide.title | translate }}</h1>
          <div class="text">{{ slide.text | translate }}</div>
        </div>
      </swiper-slide>
    }
  </swiper-container>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    @if (showingLanguageChooserPage) {
      <ion-button (click)="selectLanguage()" expand="block">
        {{ 'COMMON.NEXT' | translate }}
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </ion-button>
    } @else if (lastSlide) {
      <ion-button (click)="close()" expand="block">{{ 'WIZARD.START' | translate }}</ion-button>
    } @else {
      <ion-button (click)="next()" expand="block">
        {{ 'COMMON.NEXT' | translate }}
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </ion-button>
    }
  </ion-toolbar>
</ion-footer>

