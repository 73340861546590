import { TranslateDefaultParser } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {
  /**
   * Due to a problem accessing isDefined method in '@ngx-translate/core/lib/util', we implement it locally
   */
  isDefined(value: unknown) {
    return typeof value !== 'undefined' && value !== null;
  }

  override getValue(target: any, key: string): any {
    // We override the getValue to be able to use flatten keys in the translation JSON file.
    // we want to use
    // { "COMMON.OK": "De acuerdo" }
    // instead of
    // { "COMMON": { "OK": "De acuerdo" } }

    // We only changed this line:
    // let keys = typeof key === 'string' ? key.split('.') : [key];
    const keys = [key];
    key = '';
    do {
      key += keys.shift();
      if (this.isDefined(target) && this.isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        key = '';
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += '.';
      }
    } while (keys.length);

    return target;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override interpolate(expr: string | Function, params?: any): string {
    return super.interpolate(expr, params);
  }
}
