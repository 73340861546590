import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { TranslateLoader, TranslateModule, TranslateParser } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Drivers } from '@ionic/storage';
import { register } from "swiper/element/bundle";
import { ExperienceRepository } from "./core/experiences/domain/experience.repository";
import { HttpExperienceRepository } from "./core/experiences/infrastructure/http-experience.repository";
import { CustomTranslateParser } from "src/core/shared/translations/infrastructure/custom-translate-parser";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { IonicStorageService } from "src/core/shared/storage/infrastructure/ionic-storage.service";
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";
import { AngularTranslatorService } from "src/core/shared/translations/infrastructure/angular-translator.service";

if (environment.production) {
  enableProdMode();
}

register();

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: 'gpa_app' + (environment.database_name_suffix ? `_${environment.database_name_suffix}` : ''),
        driverOrder: [ Drivers.IndexedDB ],
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'es', // TODO: Move to a System.service.ts::DEFAULT_LANGUAGE,
        parser: {
          provide: TranslateParser,
          useClass: CustomTranslateParser,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [ HttpClient ],
        },
      }),
    ),
    {
      provide: StorageService,
      useClass: IonicStorageService,
    },
    {
      provide: TranslatorService,
      useClass: AngularTranslatorService,
    },
    {
      provide: ExperienceRepository,
      useClass: HttpExperienceRepository,
    },
  ],
});
