import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from "@angular/core";
import { addIcons } from "ionicons";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import Swiper from 'swiper';
import { NgForOf, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { arrowForwardOutline } from "ionicons/icons";
import { SessionService } from "src/core/session/domain/session.service";
import { InitializerService } from "src/core/shared/initializer/domain/initializer.service";

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.page.html',
  styleUrls: [ './wizard.page.scss' ],
  standalone: true,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    NgIf,
    IonFooter,
    IonSelect,
    IonSelectOption,
    NgForOf,
    FormsModule,
    TranslateModule,
  ],
})
export class WizardPage implements AfterViewInit {
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  showingLanguageChooserPage: boolean = true;
  lastSlide: boolean = false;
  availableLanguages: { id: string; label: string }[] = [
    { id: 'en', label: 'English' },
    { id: 'es', label: 'Español' },
    { id: 'ca', label: 'Català' },
  ];
  slides: { index: number; image: string; title: string; text: string; }[];
  selectedLanguage: string = 'en';

  constructor(private modalController: ModalController, private readonly sessionService: SessionService) {
    addIcons({ arrowForwardOutline });
    this.slides = [
      {
        index: 0,
        image: '',
        title: 'WIZARD.SLIDES.1.TITLE',
        text: 'WIZARD.SLIDES.1.TEXT',
      },
      {
        index: 1,
        image: '',
        title: 'WIZARD.SLIDES.2.TITLE',
        text: 'WIZARD.SLIDES.2.TEXT',
      },
      {
        index: 2,
        image: '',
        title: 'WIZARD.SLIDES.3.TITLE',
        text: 'WIZARD.SLIDES.3.TEXT',
      },
    ];
  }

  async ngAfterViewInit() {
    await InitializerService.waitFor(this.sessionService);
    this.selectedLanguage = this.sessionService.currentLanguage();
    console.log(`Current language is <${this.selectedLanguage}>`);
    await this.sessionService.upateLanguage(this.selectedLanguage);
    this.prepareSwiper();
  }

  prepareSwiper() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  swiperSlideChanged() {
    this.updateIndexes();
  }

  async selectLanguage() {
    await this.sessionService.upateLanguage(this.selectedLanguage);
    this.showingLanguageChooserPage = false;
  }

  async onLanguageChange(event: CustomEvent) {
    const selectedLanguage = event.detail.value;
    await this.sessionService.upateLanguage(selectedLanguage);
  }

  next() {
    this.swiper?.slideNext();
    this.updateIndexes();
  }

  async close() {
    await this.sessionService.markWizardAsDone();
    await this.modalController.dismiss();
  }

  private updateIndexes() {
    let slidesIndex = this.swiper?.activeIndex;
    this.lastSlide = slidesIndex === this.slides.length - 1;
  }

}
