import { Injectable } from "@angular/core";
import { Nullable } from "src/core/shared/types/nullable.type";

@Injectable({
  providedIn: 'root',
})
export abstract class StorageService {
  abstract init(): Promise<void>;

  abstract reset(): Promise<void>;

  abstract get(key: string): Promise<Nullable<any>>;

  abstract remove(key: string): Promise<void>;

  abstract set(key: string, value: any): Promise<void>;

  abstract ready(): Promise<void>;
}
