import { Injectable } from '@angular/core';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Initializable } from './initializable';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  static async waitFor(...services: Initializable[]): Promise<void> {
    const sources: Observable<boolean>[] = [];
    services.map((service) => {
      sources.push(
        service.ready$.pipe(
          filter((value) => value), // filter only "true" values
          take(1), // take the first true value (when service is ready)
        ),
      );
    });

    return new Promise<void>(async (resolve, reject) => {
      try {
        await lastValueFrom(forkJoin(sources));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}
