import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storable } from 'src/core/shared/storage/domain/storable';
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";

@Injectable({
  providedIn: 'root',
})
export class SessionService implements Storable, Initializable {
  private static readonly KEY_IS_LOGGED_IN = 'session.is_logged_in';
  private static readonly KEY_WIZARD_DONE = 'session.wizard_done';
  private static readonly KEY_LANGUAGE = 'session.language';

  private isLoggedIn = false;
  private wizardDone = false;
  private readonly defaultLanguage: string;
  private language: string;
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  constructor(private storage: StorageService, private translatorService: TranslatorService) {
    this.defaultLanguage = this.translatorService.defaultLanguage();
    this.language = this.defaultLanguage;
  }

  async init(): Promise<void> {
    this.isLoggedIn = (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
    this.wizardDone = (await this.storage.get(SessionService.KEY_WIZARD_DONE)) ?? false;
    this.language = (await this.storage.get(SessionService.KEY_LANGUAGE)) ?? this.defaultLanguage;

    this.translatorService.changeLanguage(this.language);
    this.readySubject.next(true);
  }

  isAuthenticated(): boolean {
    // return this.apiAccessToken !== null && this.authToken !== null && this.userInfo !== null;
    return this.isLoggedIn;
  }

  async isAuthenticatedPromise(): Promise<boolean> {
    return (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
  }

  async clear(): Promise<void> {
    this.isLoggedIn = false;
    this.wizardDone = false;
    this.language = this.defaultLanguage;
    await this.storage.remove(SessionService.KEY_IS_LOGGED_IN);
    await this.storage.remove(SessionService.KEY_WIZARD_DONE);
    await this.storage.remove(SessionService.KEY_LANGUAGE);
  }

  currentLanguage(): string {
    return this.language;
  }

  async upateLanguage(language: string) {
    this.language = language;
    this.translatorService.changeLanguage(language);
    await this.storage.set(SessionService.KEY_LANGUAGE, language);
  }

  wizardIsDone(): boolean {
    return this.wizardDone;
  }

  async markWizardAsDone() {
    this.wizardDone = true;
    await this.storage.set(SessionService.KEY_WIZARD_DONE, true);
  }
}
